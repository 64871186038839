define("discourse/plugins/discourse-epic-games/discourse/initializers/locale-listeners", ["exports", "discourse/lib/ajax", "discourse/lib/cookie", "discourse-common/lib/get-owner", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _ajax, _cookie, _getOwner, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-locale-listeners",
    initialize() {
      window.addEventListener("epic-wf-locale-on-click", this._updateLocale);
    },
    async _updateLocale(event) {
      // Prevent the default action of the event to avoid the page from reloading before we update the cookie
      // so the backend can pickup the locale change
      event.detail.originalEvent.preventDefault();

      // fetch the selected locale from the event
      const selectedLocale = event.detail.link.langCode;
      let translatedLocale = selectedLocale;
      if (_constants.DISCOURSE_LOCALE_MAP[selectedLocale]) {
        translatedLocale = _constants.DISCOURSE_LOCALE_MAP[selectedLocale];
      }
      const currentUser = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:current-user");
      if (currentUser) {
        const userPath = `/u/${currentUser.username.toLowerCase()}.json`;
        await (0, _ajax.ajax)(userPath, {
          type: "PUT",
          // since Epic uses different locale codes than Discourse, we need to map them
          // to the correct Discourse locale code. If the locale is not found in the map,
          // we'll know that the locale is already a valid syntax.
          data: {
            locale: translatedLocale
          }
        });
      }
      (0, _cookie.default)("locale", selectedLocale);

      // Reload the page to apply the new locale to Discourse and to the header/sidebar
      window.location.reload();
    },
    teardown() {
      window.removeEventListener("epic-wf-locale-on-click", this._updateLocale);
    }
  };
});