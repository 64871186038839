define("discourse/plugins/discourse-epic-games/discourse/initializers/user-badges", ["exports", "@ember/helper", "virtual-dom", "discourse/helpers/bound-avatar-template", "discourse/lib/api", "discourse/widgets/render-glimmer", "discourse-i18n", "truth-helpers/helpers/or", "discourse/plugins/discourse-epic-games/discourse/components/special-profile-classes", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _virtualDom, _boundAvatarTemplate, _api, _renderGlimmer, _discourseI18n, _or, _specialProfileClasses, _constants, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.20.0", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    if (!siteSettings.epic_badges_enabled) {
      return;
    }
    api.modifyClass("component:topic-map/topic-participant", {
      pluginId: _constants.PLUGIN_ID,
      get avatarImage() {
        const participant = this.args.participant;
        // we only load the the badges custom fields for the OP so we need to lookup
        // a participants badges from the `unreal_users_badges` that is stored on the TopicView
        const userBadge = participant.topic.unreal_users_badges?.find(b1 => b1.user_id === participant.id)?.badges;
        return (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <SpecialProfileClasses @badges={{userBadge}} />
                {{avatar
                  participant.avatar_template
                  "medium"
                  (hash title=(or participant.name participant.username))
                }}
              
        */
        {
          "id": "VANCrASB",
          "block": "[[[1,\"\\n        \"],[8,[32,0],null,[[\"@badges\"],[[32,1]]],null],[1,\"\\n        \"],[1,[28,[32,2],[[32,3,[\"avatar_template\"]],\"medium\",[28,[32,4],null,[[\"title\"],[[28,[32,5],[[32,3,[\"name\"]],[32,3,[\"username\"]]],null]]]]],null]],[1,\"\\n      \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/user-badges.js",
          "scope": () => [_specialProfileClasses.default, userBadge, _boundAvatarTemplate.default, participant, _helper.hash, _or.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      }
    });
    api.modifyClass("component:topic-map/topic-map-summary", {
      pluginId: _constants.PLUGIN_ID,
      get createdByAvatar() {
        const topicDetails = this.args.topicDetails;
        return (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <SpecialProfileClasses
                  @badges={{@topicDetails.userCustomFields.badges}}
                />
                {{avatar
                  @topicDetails.createdByAvatarTemplate
                  "tiny"
                  (hash
                    title=(or
                      @topicDetails.createdByName topicDetails.createdByUsername
                    )
                  )
                }}
              
        */
        {
          "id": "htJcM+hq",
          "block": "[[[1,\"\\n        \"],[8,[32,0],null,[[\"@badges\"],[[30,1,[\"userCustomFields\",\"badges\"]]]],null],[1,\"\\n        \"],[1,[28,[32,1],[[30,1,[\"createdByAvatarTemplate\"]],\"tiny\",[28,[32,2],null,[[\"title\"],[[28,[32,3],[[30,1,[\"createdByName\"]],[32,4,[\"createdByUsername\"]]],null]]]]],null]],[1,\"\\n      \"]],[\"@topicDetails\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/user-badges.js",
          "scope": () => [_specialProfileClasses.default, _boundAvatarTemplate.default, _helper.hash, _or.default, topicDetails],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      },
      get lastPostAvatar() {
        if (!this.args.topic?.unreal_users_badges) {
          return;
        }
        // we only load the the badges custom field for the OP so we need to lookup
        // the last post user's from the `unreal_users_badges` that is stored on the TopicView
        const userBadge = this.args.topic.unreal_users_badges.find(b1 => b1.user_id === this.args.topicDetails.participants.find(p1 => p1.username === this.args.topicDetails.last_poster.lastPostUsername)?.id)?.badges;
        return (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <SpecialProfileClasses @badges={{userBadge}} />
                {{avatar
                  @topicDetails.last_poster.lastPostAvatarTemplate
                  "tiny"
                  (hash
                    title=(or
                      @topicDetails.last_poster.lastPostName
                      @topicDetails.last_poster.lastPostUsername
                    )
                  )
                }}
              
        */
        {
          "id": "dSvu4Wv7",
          "block": "[[[1,\"\\n        \"],[8,[32,0],null,[[\"@badges\"],[[32,1]]],null],[1,\"\\n        \"],[1,[28,[32,2],[[30,1,[\"last_poster\",\"lastPostAvatarTemplate\"]],\"tiny\",[28,[32,3],null,[[\"title\"],[[28,[32,4],[[30,1,[\"last_poster\",\"lastPostName\"]],[30,1,[\"last_poster\",\"lastPostUsername\"]]],null]]]]],null]],[1,\"\\n      \"]],[\"@topicDetails\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/user-badges.js",
          "scope": () => [_specialProfileClasses.default, userBadge, _boundAvatarTemplate.default, _helper.hash, _or.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      }
    });
    api.reopenWidget("post-avatar", {
      buildClasses(attrs1) {
        if (!attrs1.userCustomFields?.["badges"]) {
          return;
        }
        const badgeIds1 = attrs1.userCustomFields["badges"]?.split(",")?.map(e1 => parseInt(e1, 10));
        // always return staff badge or hero badge first if present
        if (badgeIds1?.includes(1)) {
          return "unreal-highlight-staff";
        } else if (badgeIds1?.includes(0)) {
          return "unreal-highlight-hero";
        } else if (badgeIds1?.includes(2)) {
          return "unreal-highlight-contributor";
        } else {
          return;
        }
      }
    });
    api.decorateWidget("post-avatar:after", function (helper1) {
      const model1 = helper1.getModel();
      const isDiscourseOnlyUser1 = _constants.DISCOURSE_SYSTEM_USERS.includes(model1.username);
      const userUrl1 = isDiscourseOnlyUser1 ? model1.usernameUrl : `${siteSettings.epic_dev_community_url}${siteSettings.epic_username_link_path_prefix}${model1.username}`;
      return [new _renderGlimmer.default(helper1.widget, "div.ue-badges-for-post", (0, _templateFactory.createTemplateFactory)(
      /*
        <UeBadges @badges={{@data.badges}} @teamMemberships={{@data.teamMemberships}} @limit={{1}} @url={{@data.url}} @username={{@data.username}} @displayName={{@data.displayName}}/>
      */
      {
        "id": "UG7UAPP2",
        "block": "[[[8,[39,0],null,[[\"@badges\",\"@teamMemberships\",\"@limit\",\"@url\",\"@username\",\"@displayName\"],[[30,1,[\"badges\"]],[30,1,[\"teamMemberships\"]],1,[30,1,[\"url\"]],[30,1,[\"username\"]],[30,1,[\"displayName\"]]]],null]],[\"@data\"],false,[\"ue-badges\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/user-badges.js",
        "isStrictMode": false
      }), {
        badges: model1.user_custom_fields?.badges,
        teamMemberships: model1.user_custom_fields?.team_memberships,
        url: userUrl1,
        username: model1.username,
        displayName: model1.name
      }), (0, _virtualDom.h)("div.post-user-stats", [(0, _virtualDom.h)("div.post-user-topic-count", [(0, _virtualDom.h)("span", [model1.unreal_post_count, (0, _virtualDom.h)("div.post-user-count-label", [_discourseI18n.default.t("discourse_epic_games.epic_badges.profile.stats.posts")])])]), (0, _virtualDom.h)("div.post-user-answers-count", [(0, _virtualDom.h)("span", [model1.unreal_user_answers_count, (0, _virtualDom.h)("div.post-user-answers-count-label", [_discourseI18n.default.t("discourse_epic_games.epic_badges.profile.stats.answers")])])])])];
    });
    api.decorateWidget("post:before", function (attrs1) {
      const userBadges1 = attrs1.widget.model.user_custom_fields?.badges;
      if (!userBadges1) {
        return;
      }
      const badgeArray1 = userBadges1.split(",").map(e1 => parseInt(e1, 10));
      let specialUser1;
      if (badgeArray1.includes(1)) {
        specialUser1 = "staff";
      } else if (badgeArray1.includes(2)) {
        specialUser1 = "contributor";
      } else if (badgeArray1.includes(0)) {
        specialUser1 = "hero";
      }
      if (specialUser1) {
        return (0, _virtualDom.h)("div.post-user-highlight-" + specialUser1);
      }
    });
  });
});