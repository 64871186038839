define("discourse/plugins/discourse-epic-games/discourse/initializers/topic-list-likes", ["exports", "discourse/components/topic-list"], function (_exports, _topicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-modify-topic-list-for-likes",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_enable_likes_count_on_topic_list) {
        return;
      }

      // show likes count on topic list
      _topicList.default.reopen({
        showLikes: true
      });
    }
  };
});