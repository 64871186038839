define("discourse/plugins/discourse-epic-games/discourse/pre-initializers/qixel-redirects", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-qixel-redirects",
    initialize(container) {
      const router = container.lookup("service:router");
      (0, _pluginApi.withPluginApi)("1.6.0", () => {
        // epic has requested a temporary permalink for quixel categories
        // see https://meta.discourse.org/t/request-to-set-up-redirect-from-moved-categories/328287/1 for context
        router.on("routeWillChange", transition => {
          const url = transition?.intent?.url;
          if (url === "/tags/c/development-discussion/quixel-megascans/showcase/202/unreal-engine") {
            transition.abort();
            // we want to trigger a page refresh to ensure that the application is updated
            // because we are transitioning from one application to another.
            window.location = "/tags/c/development-discussion/quixel-megascans/showcase/202/fab";
          } else if (url === "/tags/c/development-discussion/quixel-megascans/200/unreal-engine") {
            transition.abort();
            // we want to trigger a page refresh to ensure that the application is updated
            // because we are transitioning from one application to another.
            window.location = "/tags/c/development-discussion/quixel-megascans/200/fab";
          }
        });
      });
    }
  };
});