define("discourse/plugins/discourse-epic-games/discourse/initializers/modify-breadcrumb-selectors", ["exports", "@ember/service", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _service, _pluginApi, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-modify-breadcrumb-selectors",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        api.modifyClass("component:category-drop", {
          pluginId: _constants.PLUGIN_ID,
          site: (0, _service.service)(),
          selectKitOptions: {
            caretDownIcon: "ue-caret-down",
            caretUpIcon: "ue-caret-up",
            showFullTitle: false
          }
        });
        api.modifyClass("component:tag-drop", {
          pluginId: _constants.PLUGIN_ID,
          selectKitOptions: {
            caretDownIcon: "ue-caret-down",
            caretUpIcon: "ue-caret-up"
          }
        });
        api.modifyClass("component:dropdown-select-box", {
          pluginId: _constants.PLUGIN_ID,
          selectKitOptions: {
            caretDownIcon: "ue-caret-down",
            caretUpIcon: "ue-caret-up",
            showFullTitle: false
          }
        });
      });
    }
  };
});