define("discourse/plugins/discourse-epic-games/discourse/initializers/search-init", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-search-init",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.forceDropdownForMenuPanels("search-menu-panel");
      });
    }
  };
});