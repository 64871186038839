define("discourse/plugins/discourse-epic-games/discourse/raw-views/status-badge", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _object, _computed, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(dt7948.p({
    siteSettings: (0, _service.service)(),
    hasAnswer: (0, _computed.alias)("topic.has_accepted_answer"),
    isQuestion(tags) {
      if (tags) {
        return tags.indexOf(this.siteSettings.epic_solved_filter_tag) > -1;
      } else {
        return false;
      }
    }
  }, [["method", "isQuestion", [(0, _decorators.default)("topic.tags")]]]));
});