define("discourse/plugins/discourse-epic-games/discourse/initializers/create-logout-listener", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-create-logout-listener",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.14", () => {
        document.addEventListener("epic-wf-account-link-on-click", event => {
          if (event.detail.link.key === "signout") {
            container.lookup("route:application").send("logout");
            event.detail.originalEvent.preventDefault();
          }
        });
      });
    }
  };
});