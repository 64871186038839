define("discourse/plugins/discourse-epic-games/discourse/initializers/unreal-sidebar", ["exports", "@ember/object", "@ember/service", "discourse/lib/plugin-api", "discourse/models/category-list", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _object, _service, _pluginApi, _categoryList, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-unreal-sidebar",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const unrealSidebar = container.lookup("service:unreal-sidebar");
      if (siteSettings.discourse_global_filter_enabled) {
        // callback to update the sidebar categories everytime CategoryList.list is called
        _categoryList.default.globalFilterListCallbacks?.push(list => {
          unrealSidebar.updateCategories(list);
        });
        (0, _pluginApi.withPluginApi)("0.8.14", api => {
          api.modifyClass("service:site", {
            pluginId: _constants.PLUGIN_ID,
            unrealSidebar: (0, _service.service)(),
            globalFilterObserver: (0, _object.observer)("globalFilter", function () {
              // the observer tracks modifications to site.globalFilter but can be updated in quick succession
              // loadCategories ignores the subsequent request if the globalFilter is the same
              this.unrealSidebar.loadCategories(this.globalFilter);
            })
          });
        });
      }
    }
  };
});