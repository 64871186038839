define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicStatusBar extends _component.default {
    static shouldRender(outletArgs1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return outletArgs1.model?.tags?.includes(siteSettings1.epic_solved_filter_tag);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="custom-topic-status-bar
            {{if @outletArgs.model.accepted_answer 'resolved'}}"
        >
          <span>
            {{i18n "discourse_epic_games.topic_status.qa_topic"}}
            <span>|</span>
            {{#if @outletArgs.model.accepted_answer}}
              {{i18n "discourse_epic_games.topic_status.status_badge.solved"}}
            {{else}}
              {{i18n "discourse_epic_games.topic_status.status_badge.unsolved"}}
            {{/if}}
          </span>
        </div>
      
    */
    {
      "id": "mTpY6WH2",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"custom-topic-status-bar\\n        \",[52,[30,1,[\"model\",\"accepted_answer\"]],\"resolved\"]]]],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.qa_topic\"],null]],[1,\"\\n        \"],[10,1],[12],[1,\"|\"],[13],[1,\"\\n\"],[41,[30,1,[\"model\",\"accepted_answer\"]],[[[1,\"          \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.solved\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.unsolved\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicStatusBar;
});