define("discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-with-results-bottom/advanced-search", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/advanced-search-link", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _advancedSearchLink, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdvancedSearch = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <AdvancedSearchLink />
  */
  {
    "id": "cQ6yI1PD",
    "block": "[[[8,[32,0],null,null,null]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-with-results-bottom/advanced-search.js",
    "scope": () => [_advancedSearchLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "advanced-search:AdvancedSearch"));
  var _default = _exports.default = AdvancedSearch;
});