define("discourse/plugins/discourse-epic-games/discourse/connectors/extra-nav-item/more-items-dropdown", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-epic-games/discourse/components/nav-link-dropdown", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _navLinkDropdown, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MoreItemsDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.desktopView}}
          <NavLinkDropdown @category={{@outletArgs.category}} />
        {{/if}}
      
    */
    {
      "id": "LwqPQrAy",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@category\"],[[30,1,[\"category\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/extra-nav-item/more-items-dropdown.js",
      "scope": () => [_navLinkDropdown.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MoreItemsDropdown;
});