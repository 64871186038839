define("discourse/plugins/discourse-epic-games/discourse/initializers/create-topic-on-question", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-i18n"], function (_exports, _pluginApi, _composer, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function askingQuestion(composer) {
    return (composer.tags || []).includes("question") && composer.action === _composer.CREATE_TOPIC;
  }
  var _default = _exports.default = {
    name: "epic-create-topic-on-question",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        // Change the label when asking a question
        api.customizeComposerText({
          actionTitle(model) {
            if (askingQuestion(model)) {
              return _discourseI18n.default.t("discourse_epic_games.ask_question");
            }
          },
          saveLabel(model) {
            if (askingQuestion(model)) {
              return "discourse_epic_games.ask_question";
            }
          }
        });
      });
    }
  };
});