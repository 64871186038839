define("discourse/plugins/discourse-epic-games/discourse/initializers/topic-voting-thumbs-up", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-topic-voting-thumbs-up",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.decorateWidget("vote-count:before", helper => {
          return helper.h("a.icon", {}, (0, _iconLibrary.iconNode)("ue-thumbs-up"));
        });
      });
    }
  };
});