define("discourse/plugins/discourse-epic-games/discourse/initializers/dsa-report", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/models/post-action-type", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent", "discourse/plugins/discourse-epic-games/discourse/lib/dsa-report-url"], function (_exports, _object, _pluginApi, _postActionType, _constants, _digitalConsent, _dsaReportUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-dsa-report",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_enable_dsa_external_report) {
        return;
      }
      const currentUser = container.lookup("service:current-user");
      const toasts = container.lookup("service:toasts");
      document.body.classList.add("dsa-report-enabled");
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.registerTopicFooterButton({
          id: "dsa-report-topic",
          action() {
            const reportUrl = (0, _dsaReportUrl.default)({
              contentUrl: `/t/${this.topic.id}`,
              contentType: "discourse_topic",
              dsaReportUrl: this.siteSettings.epic_dsa_report_url
            });
            window.open(reportUrl, "_blank");
          },
          icon: "flag",
          label: "discourse_epic_games.dsa_report.report_topic",
          anonymousOnly: true
        });
        api.addPostMenuButton("dsa-report", attrs => {
          if (attrs.canFlag || currentUser?.staff ||
          // don't show dsa-report flag button for system users
          attrs.user_id <= 0) {
            return;
          }

          // If flagging is disabled, shows a direct link to the external report form
          // for non system user posts
          if (attrs.user_id > 0) {
            return {
              icon: "flag",
              action: "dsaReportPost",
              title: "discourse_epic_games.dsa_report.report_post",
              className: "btn btn-default dsa-report-post",
              position: "second-last-hidden"
            };
          }
        });
        api.attachWidgetAction("post", "dsaReportPost", function () {
          const reportUrl = (0, _dsaReportUrl.default)({
            contentUrl: `/p/${this.model.id}`,
            contentType: "discourse_post",
            dsaReportUrl: this.siteSettings.epic_dsa_report_url
          });
          window.open(reportUrl, "_blank");
        });
        api.modifyClass("component:modal/flag", dt7948.p({
          pluginId: _constants.PLUGIN_ID,
          get isDiscourseOnlyAuthor() {
            return _constants.DISCOURSE_SYSTEM_USERS.includes(this.args.model.flagModel.username);
          },
          get submitEnabled() {
            if (!this.selected) {
              return false;
            }
            if (!this.isDiscourseOnlyAuthor && _constants.DSA_FLAG_TYPES.includes(this.selected?.name_key)) {
              return true;
            }
            if (!this.selected.is_custom_flag) {
              return true;
            }
            const len = this.message?.length || 0;
            return len >= this.siteSettings.min_personal_message_post_length && len <= _postActionType.MAX_MESSAGE_LENGTH;
          },
          changePostActionType(actionType) {
            if (!this.isDiscourseOnlyAuthor && _constants.DSA_FLAG_TYPES.includes(actionType?.name_key)) {
              actionType.set("is_custom_flag", false);
            }
            this.selected = actionType;
          },
          createFlag(opts) {
            // opts is only passed for staff actions
            const isSystemTopic = this.args.model?.flagModel?._details?.created_by?.id === -1;
            if (opts || !_constants.DSA_FLAG_TYPES.includes(this.selected?.name_key) || this.isDiscourseOnlyAuthor || isSystemTopic) {
              if (this.selected.is_custom_flag) {
                opts = {
                  ...opts,
                  message: this.message
                };
              }

              // don't let the user complete creating a discourse flag on the post if they are in DIGITAL_CONSENT_PENDING_GROUP
              if (siteSettings.epic_digital_consent_cta_enabled && (0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
                return;
              }
              return this.args.model.flagTarget.create(this, opts);
            }
            const model = this.args.model.flagModel;
            let contentUrl, contentType;
            if (this.args.model.flagTarget.targetsTopic()) {
              contentUrl = `/t/${model.id}`;
              const isPrivate = isPrivateTopic(model);
              contentType = isPrivate ? "discourse_topic_private" : "discourse_topic";
            } else {
              contentUrl = `/p/${model.id}`;
              const isPrivate = isPrivateTopic(model.topic);
              contentType = isPrivate ? "discourse_post_private" : "discourse_post";
            }
            const reportUrl = (0, _dsaReportUrl.default)({
              contentUrl,
              contentType,
              dsaReportUrl: this.siteSettings.epic_dsa_report_url
            });
            window.open(reportUrl, "_blank");
          }
        }, [["method", "changePostActionType", [_object.action]], ["method", "createFlag", [_object.action]]]));
      });
    }
  };
  function isPrivateTopic(topic) {
    return topic.archetype === "private_message" || topic.category?.read_restricted;
  }
});