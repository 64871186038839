define("discourse/plugins/discourse-epic-games/discourse/initializers/thumbnails-tag-routes", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _pluginApi, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-thumbnails-tag-category-routes",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const minimalGridCategories = siteSettings.epic_minimal_grid_categories?.split("|").map(id => parseInt(id, 10));
      (0, _pluginApi.withPluginApi)("0.11.5", api => {
        api.modifyClass("route:tags.showCategory", {
          pluginId: _constants.PLUGIN_ID,
          buildRouteInfoMetadata() {
            // the global filters plugin forces all category routes to be tags.showCategory
            // the topic thumbnails component doesn't have a configuration for this
            // so we need to add one here
            if (minimalGridCategories?.includes(this.context?.category?.id)) {
              return {
                customThumbnailMode: "minimal-grid"
              };
            } else {
              return {};
            }
          }
        });
      });
    }
  };
});