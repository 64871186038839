define("discourse/plugins/discourse-epic-games/discourse/components/nav-link-dropdown-row", ["exports", "discourse/lib/url", "select-kit/components/select-kit/select-kit-row"], function (_exports, _url, _selectKitRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectKitRow.default.extend({
    classNames: ["nav-link-dropdown-row"],
    get label() {
      return this.item.displayName;
    },
    click(event) {
      event.preventDefault();
      event.stopPropagation();
      _url.default.routeTo(this.item.href);
      return false;
    }
  });
});