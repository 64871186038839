define("discourse/plugins/discourse-epic-games/discourse/initializers/header-scroll-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-header-scroll-check",
    initialize() {
      window.onscroll = function scrollTop() {
        const header = document.querySelector(".d-header-wrap");
        const extraInfo = document.querySelector(".extra-info-wrapper");
        const offset = 40;
        header.classList.toggle("scrolled-header", window.scrollY > offset);
        header.classList.toggle("showing-topic-info", extraInfo);
      };
    }
  };
});