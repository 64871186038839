define("discourse/plugins/discourse-epic-games/discourse/pre-initializers/disable-keyboard-shortcuts-digital-consent", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent"], function (_exports, _pluginApi, _digitalConsent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-disable-keyboard-shortcuts-digital-consent",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_digital_consent_cta_enabled) {
        return;
      }
      if (!(0, _digitalConsent.hasDigitalConsentPendingGroup)(currentUser)) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        api.disableDefaultKeyboardShortcuts(["t",
        // disable reply as linked topic
        "shift+r",
        // disable reply as new topic
        "r",
        // disable reply to topic
        "quoteReply",
        // disable quote reply
        "l",
        // disable like
        "f",
        // disable toggleBookmarkTopic
        "e",
        // disable edit post
        "d",
        // disable delete post
        "c",
        // disable create new topic
        "b" // disable bookmark
        ]);
      });
    }
  };
});