define("discourse/plugins/discourse-epic-games/discourse/connectors/above-site-header/unreal-site-header", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/unreal-nav", "discourse/plugins/discourse-epic-games/discourse/components/unreal-sidebar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _unrealNav, _unrealSidebar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UnrealSiteHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <UnrealSidebar />
    <UnrealNav />
  
  */
  {
    "id": "8rCwc90l",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,null,null],[1,\"\\n  \"],[8,[32,1],null,null,null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/above-site-header/unreal-site-header.js",
    "scope": () => [_unrealSidebar.default, _unrealNav.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "unreal-site-header:UnrealSiteHeader"));
  var _default = _exports.default = UnrealSiteHeader;
});