define("discourse/plugins/discourse-epic-games/discourse/components/search-type-header", ["exports", "@ember/helper", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SearchTypeHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="search-type-header">
      <h5 class="result-type-header">
        {{i18n (concat "discourse_epic_games.search_menu.types." @type)}}
      </h5>
    </div>
  
  */
  {
    "id": "A3sSC08D",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"search-type-header\"],[12],[1,\"\\n    \"],[10,\"h5\"],[14,0,\"result-type-header\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[28,[32,1],[\"discourse_epic_games.search_menu.types.\",[30,1]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@type\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/search-type-header.js",
    "scope": () => [_i18n.default, _helper.concat],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "search-type-header:SearchTypeHeader"));
  var _default = _exports.default = SearchTypeHeader;
});