define("discourse/plugins/discourse-epic-games/discourse/pre-initializers/user-profile-link-override", ["exports", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _pluginApi, _url, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: _constants.PLUGIN_ID,
    before: "inject-discourse-objects",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_enable_external_username_link) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        // Replace the user url in general
        // Skip the current user to avoid breaking user menu routes
        api.modifyClass("model:user", {
          pluginId: _constants.PLUGIN_ID,
          get path() {
            return getUserUrl(this.username, true);
          }
        });

        // Replace the user url on posts; it doesn't use model.path
        api.addPostTransformCallback(transformed => {
          transformed.usernameUrl = getUserUrl(transformed.username, false);
        });

        // Replace mention urls
        api.decorateCookedElement(post => post.querySelectorAll("a.mention").forEach(mention => {
          const [, username] = mention.href.match(/u\/(.*)/);
          if (username) {
            mention.href = getUserUrl(username, false);
          }
        }));
        function getUserUrl(username, skipCurrentUser) {
          const isCurrentUser = api.getCurrentUser()?.username === username;
          const isSystemUser = _constants.DISCOURSE_SYSTEM_USERS.includes(username);
          return isSystemUser || isCurrentUser && skipCurrentUser ? (0, _url.userPath)(username.toLowerCase()) : `${siteSettings.epic_dev_community_url}${siteSettings.epic_username_link_path_prefix}${username}`;
        }
      });
    }
  };
});