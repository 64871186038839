define("discourse/plugins/discourse-epic-games/discourse/connectors/after-create-topic-button/new-question-button", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/new-topic-dropdown", "@ember/component", "@ember/template-factory"], function (_exports, _component, _newTopicDropdown, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewQuestionButton extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        currentUser: currentUser1
      } = _ref;
      return !!currentUser1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <NewTopicDropdown
          @category={{@outletArgs.category}}
          @tag={{@outletArgs.tag.id}}
        />
      
    */
    {
      "id": "A9gEIjE6",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@category\",\"@tag\"],[[30,1,[\"category\"]],[30,1,[\"tag\",\"id\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/after-create-topic-button/new-question-button.js",
      "scope": () => [_newTopicDropdown.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NewQuestionButton;
});