define("discourse/plugins/discourse-epic-games/discourse/connectors/before-filtered-category-drop/category-name", ["exports", "@glimmer/component", "@ember/runloop", "@ember/component", "@ember/template-factory"], function (_exports, _component, _runloop, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryName extends _component.default {
    constructor() {
      super(...arguments);
      this.hideSecondLastDropDetails();
      this.updateTagIntersectionStyles();
    }
    // when displaying the breadcrumbs, if we are within a sub category we
    // don't want to use the default category chooser as it is not filtered
    // to the global filter's filtered categories. So we have to hide the default
    // and use the filtered category chooser.
    hideSecondLastDropDetails() {
      if (this.#categoryExists || this.#categoryDrops.length <= 1) {
        return;
      }
      if (this.#lastDrop?.previousElementSibling !== this.#secondLastDrop) {
        return;
      }
      (0, _runloop.next)(() => {
        this.#secondLastDrop.querySelector("details").style = "display: none;";
      });
    }
    // if we are on the tag intersection route then we need to add some
    // custom styles to fit epic's needs. These are fragile and hopefully
    // can be removed in a future UI rewrite
    updateTagIntersectionStyles() {
      if (this.#categoryExists || !this.#tagIntersectionChooser) {
        return;
      }
      const navigationContainer1 = this.#navigationContainer;
      navigationContainer1.style.justifyContent = "space-between";
      navigationContainer1.querySelector("ol.category-breadcrumb").style.width = "unset";
    }
    get #categoryDrops() {
      return document.querySelectorAll(".filtered-category-drop");
    }
    get #lastDrop() {
      const drops1 = this.#categoryDrops;
      return drops1[drops1.length - 1];
    }
    get #secondLastDrop() {
      const drops1 = this.#categoryDrops;
      return drops1[drops1.length - 2];
    }
    get #tagIntersectionChooser() {
      return document.querySelector(".tags-intersection-chooser");
    }
    get #navigationContainer() {
      return document.querySelector(".navigation-container");
    }
    get #categoryExists() {
      return !!this.args.outletArgs.category;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.category}}
          <a href="/c/{{@outletArgs.category.id}}" class="unreal-breadcrumb-item">
            {{@outletArgs.category.name}}
          </a>
        {{/if}}
      
    */
    {
      "id": "wG5JuNQz",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"category\"]],[[[1,\"      \"],[10,3],[15,6,[29,[\"/c/\",[30,1,[\"category\",\"id\"]]]]],[14,0,\"unreal-breadcrumb-item\"],[12],[1,\"\\n        \"],[1,[30,1,[\"category\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/before-filtered-category-drop/category-name.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryName;
});