define("discourse/plugins/discourse-epic-games/discourse/initializers/clickable-topic-list-item", ["exports", "discourse/lib/intercept-click", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _interceptClick, _pluginApi, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "clickable-topic-list-item",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_enable_clickable_topic_list_item) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("component:topic-list-item", {
          pluginId: _constants.PLUGIN_ID,
          click(event) {
            const result = this.showEntrance(event);
            if (result === false) {
              return result;
            }
            const target = event.target;
            if (target.classList.contains("bulk-select")) {
              const selected = this.selected;
              if (target.checked) {
                selected.addObject(this.topic);
              } else {
                selected.removeObject(this.topic);
              }
            }
            if (target.classList.contains("raw-topic-link")) {
              if ((0, _interceptClick.wantsNewWindow)(event)) {
                return true;
              }
              return this.navigateToTopic(this.topic, target.href);
            }

            // make full row click target
            if (target.tagName === "TD" || target.classList.contains("link-bottom-line") || target.classList.contains("link-top-line")) {
              if ((0, _interceptClick.wantsNewWindow)(event)) {
                return true;
              }
              return this.navigateToTopic(this.topic, this.topic.lastUnreadUrl);
            }
            if (target.closest("a.topic-status") !== null) {
              this.topic.togglePinnedForUser();
              return false;
            }
            return this.unhandledRowClick(event, this.topic);
          }
        });
        api.modifyClass("component:latest-topic-list-item", {
          pluginId: _constants.PLUGIN_ID,
          click(event) {
            // for discourse events, undefined has a different meaning than false
            if (this.showEntrance(event) === false) {
              return false;
            }
            const target = document.querySelector(event.target);

            // make full row click target
            if (target.tagName === "DIV") {
              if ((0, _interceptClick.wantsNewWindow)(event)) {
                return true;
              }
              return this.navigateToTopic(this.topic, this.topic.lastUnreadUrl);
            }
            return this.unhandledRowClick(event, this.topic);
          }
        });
      });
    }
  };
});