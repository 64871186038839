define("discourse/plugins/discourse-epic-games/discourse/connectors/before-user-profile-avatar/profile-avatar-wrapper", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/special-profile-classes", "discourse/plugins/discourse-epic-games/discourse/components/user-profile-background-image", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _specialProfileClasses, _userProfileBackgroundImage, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <UserProfileBackgroundImage @user={{@outletArgs.model}} />
    <SpecialProfileClasses @user={{@outletArgs.model}} />
  
  */
  {
    "id": "zb2ivMR0",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n  \"],[8,[32,1],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/before-user-profile-avatar/profile-avatar-wrapper.js",
    "scope": () => [_userProfileBackgroundImage.default, _specialProfileClasses.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "profile-avatar-wrapper"));
});