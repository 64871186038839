define("discourse/plugins/discourse-epic-games/discourse/lib/inject-composer-filter-tag", ["exports", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectComposerFilterTag = injectComposerFilterTag;
  function injectComposerFilterTag(filter, deselect, tags) {
    if (filter && _constants.REALITY_CAPTURE_OR_FORTNITE_FILTERS.includes(filter)) {
      if (deselect) {
        switch (filter) {
          // remove tags for capturing reality
          case "reality-capture":
            if (tags.includes("reality-scan")) {
              tags.push("capturing-reality");
            }
            break;
          case "reality-scan":
            if (tags.includes("reality-capture")) {
              tags.push("capturing-reality");
            }
            break;
          // remove tags for fortnite
          case "fortnite-creative":
            if (tags.includes("unreal-editor-for-fortnite")) {
              tags.push("fortnite");
            }
            break;
          case "unreal-editor-for-fortnite":
            if (tags.includes("fortnite-creative")) {
              tags.push("fortnite");
            }
            break;
        }
      } else {
        switch (filter) {
          // hack in tags for capturing reality
          case "capturing-reality":
            tags.push("capturing-reality", "reality-capture", "reality-scan");
            break;
          case "reality-capture":
            tags.push("capturing-reality");
            break;
          case "reality-scan":
            tags.push("capturing-reality");
            break;
          // hack in tags for fortnite
          case "fortnite":
            tags.push("fortnite", "fortnite-creative", "unreal-editor-for-fortnite");
            break;
          case "fortnite-creative":
            tags.push("fortnite");
            break;
          case "unreal-editor-for-fortnite":
            tags.push("fortnite");
            break;
        }
      }
    } else {
      if (deselect) {
        tags = tags.filter(t => t !== filter);
      } else {
        tags.push(filter);
      }
    }
  }
});