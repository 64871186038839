define("discourse/plugins/discourse-epic-games/discourse/components/bug-report-in-post-fixed-status", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/template", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/formatter", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _template2, _dButton, _concatClass, _formatter, _dIcon, _i18n, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BugReportInPostFixedStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "drawerOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #drawerOpen = (() => (dt7948.i(this, "drawerOpen"), void 0))();
    statusObject = (() => _constants.BUG_REPORT_TOPIC_STATUSES[_constants.FIXED_STATUS])();
    status = (() => (0, _i18n.default)(this.statusObject.status))();
    get createdAt() {
      return (0, _i18n.default)(this.statusObject.created_at, {
        date: (0, _formatter.shortDate)(this.args.topic.created_at)
      });
    }
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleDrawer", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass
            "bug-report-in-post-fixed-status-wrapper"
            (if this.drawerOpen "show-fixed-status-details")
          }}
        >
          <div class="fixed-status-top-items">
            <div class="fixed-status-status">
              <div class="fixed-status-icon">
                {{icon "check-square"}}
              </div>
              <span>{{this.status}}</span>
            </div>
            <DButton
              class="fixed-status-toggle"
              @icon={{concat "chevron-" (if this.drawerOpen "up" "down")}}
              @action={{this.toggleDrawer}}
            />
          </div>
          {{#if this.drawerOpen}}
            <div class="fixed-status-details">
              <div class="fixed-status-details-message">
                {{htmlSafe this.createdAt}}
              </div>
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "++XKeHHs",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"bug-report-in-post-fixed-status-wrapper\",[52,[30,0,[\"drawerOpen\"]],\"show-fixed-status-details\"]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"fixed-status-top-items\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"fixed-status-status\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"fixed-status-icon\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"check-square\"],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[12],[1,[30,0,[\"status\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,2],[[24,0,\"fixed-status-toggle\"]],[[\"@icon\",\"@action\"],[[28,[32,3],[\"chevron-\",[52,[30,0,[\"drawerOpen\"]],\"up\",\"down\"]],null],[30,0,[\"toggleDrawer\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"drawerOpen\"]],[[[1,\"        \"],[10,0],[14,0,\"fixed-status-details\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"fixed-status-details-message\"],[12],[1,\"\\n            \"],[1,[28,[32,4],[[30,0,[\"createdAt\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/bug-report-in-post-fixed-status.js",
      "scope": () => [_concatClass.default, _dIcon.default, _dButton.default, _helper.concat, _template2.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BugReportInPostFixedStatus;
});