define("discourse/plugins/discourse-epic-games/discourse/connectors/user-post-names/badges", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/ue-badges", "@ember/component", "@ember/template-factory"], function (_exports, _component, _ueBadges, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Badges extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.epic_badges_enabled;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <UeBadges
          @badges={{@outletArgs.model.custom_fields.badges}}
          @teamMemberships={{@outletArgs.model.custom_fields.team_memberships}}
        />
      
    */
    {
      "id": "qqD8aGDp",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@badges\",\"@teamMemberships\"],[[30,1,[\"model\",\"custom_fields\",\"badges\"]],[30,1,[\"model\",\"custom_fields\",\"team_memberships\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/user-post-names/badges.js",
      "scope": () => [_ueBadges.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Badges;
});