define("discourse/plugins/discourse-epic-games/discourse/initializers/solved-button-location", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library", "discourse-i18n"], function (_exports, _pluginApi, _iconLibrary, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // changes position of solution button by editing the position const...
  // it should always show for those that can accept an answer, whether or not they're OP
  var _default = _exports.default = {
    name: "epic-solved-button-position",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.addPostMenuButton("solved", attrs => {
          const canAccept = attrs.can_accept_answer;
          const canUnaccept = attrs.can_unaccept_answer;
          const accepted = attrs.accepted_answer;
          const position = !accepted && canAccept ? "first" : "second-last-hidden";
          if (canAccept) {
            return {
              action: "acceptAnswer",
              icon: "ue-check",
              className: "unaccepted",
              title: "solved.accept_answer",
              label: "solved.solution",
              position
            };
          } else if (canUnaccept && accepted) {
            const title = canUnaccept ? "solved.unaccept_answer" : "solved.accepted_answer";
            return {
              action: "unacceptAnswer",
              icon: "ue-check",
              title,
              className: "accepted fade-out",
              position,
              label: "solved.solution"
            };
          } else if (!canAccept && accepted) {
            return {
              className: "hidden",
              disabled: "true",
              position,
              beforeButton(h) {
                return h("span.accepted-text", {
                  title: _discourseI18n.default.t("solved.accepted_description")
                }, [h("span", (0, _iconLibrary.iconNode)("ue-check")), h("span.accepted-label", _discourseI18n.default.t("solved.solution"))]);
              }
            };
          }
        });
      });
    }
  };
});