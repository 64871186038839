define("discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-controls/dsa-report-user-button", ["exports", "@glimmer/component", "truth-helpers", "discourse/plugins/discourse-epic-games/discourse/components/dsa-report-user", "@ember/component", "@ember/template-factory"], function (_exports, _component, _truthHelpers, _dsaReportUser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DsaReportUserButton extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.epic_enable_dsa_external_report;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! Add a button to report a user in the user profile page for non system users }}
        {{#if (gt @outletArgs.model.id 0)}}
          <DsaReportUser @user={{@outletArgs.model}} />
        {{/if}}
      
    */
    {
      "id": "ogESBpxW",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"model\",\"id\"]],0],null],[[[1,\"      \"],[8,[32,1],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-controls/dsa-report-user-button.js",
      "scope": () => [_truthHelpers.gt, _dsaReportUser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DsaReportUserButton;
});