define("discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-assistant-user-top/assistant-user-top", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/search-type-header", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _searchTypeHeader, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AssistantUserTop = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <SearchTypeHeader @type="user" />
  */
  {
    "id": "s90eMrrO",
    "block": "[[[8,[32,0],null,[[\"@type\"],[\"user\"]],null]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-assistant-user-top/assistant-user-top.js",
    "scope": () => [_searchTypeHeader.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "assistant-user-top:AssistantUserTop"));
  var _default = _exports.default = AssistantUserTop;
});