define("discourse/plugins/discourse-epic-games/discourse/templates/connectors/user-card-content-container/empty-user-card-override", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-html-comments }}
  <!-- Disabled user card -->
  */
  {
    "id": "lPQE3msk",
    "block": "[[[3,\" Disabled user card \"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-epic-games/discourse/templates/connectors/user-card-content-container/empty-user-card-override.hbs",
    "isStrictMode": false
  });
});