define("discourse/plugins/discourse-epic-games/discourse/initializers/modify-global-filter-chooser", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "discourse/plugins/discourse-epic-games/discourse/lib/open-composer"], function (_exports, _runloop, _pluginApi, _constants, _openComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-modify-global-filter-chooser",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        const siteSettings = api.container.lookup("service:site-settings");
        if (!siteSettings.discourse_global_filter_enabled) {
          return;
        }
        api.modifyClass("component:global-filter-chooser", {
          pluginId: _constants.PLUGIN_ID,
          select(value) {
            this._super(...arguments);
            (0, _runloop.next)(() => {
              (0, _openComposer.openComposer)({
                filter: value,
                editing: true
              });
            });
          },
          deselect(value) {
            this._super(...arguments);
            (0, _runloop.next)(() => {
              (0, _openComposer.openComposer)({
                filter: value.name,
                deselect: true,
                editing: true
              });
            });
          }
        });
      });
    }
  };
});