define("discourse/plugins/discourse-epic-games/discourse/initializers/load-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-load-sidebar",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      addScript(siteSettings.epic_sidebar_url);
    }
  };
  function addScript(src) {
    let s = document.createElement("script");
    s.setAttribute("src", src);
    s.setAttribute("type", "module");
    document.head.appendChild(s);
  }

  // dev url: https://components-dev.edc-cdn.net/sidebar/edc-sidebar.js
  // staging url: https://components-stage.edc-cdn.net/sidebar/edc-sidebar.js
  // prod url: https://components.edc-cdn.net/sidebar/edc-sidebar.js
});