define("discourse/plugins/discourse-epic-games/discourse/components/subcategory-list-buttons", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubcategoryListButtons = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @subcategories}}
      <div class="filtered-subcategory-list-buttons">
        {{#each @subcategories as |subcategory|}}
          <a class="subcategory" href="/c/{{subcategory.id}}">
            <h3>{{subcategory.name}}</h3>
          </a>
        {{/each}}
      </div>
    {{/if}}
  
  */
  {
    "id": "OChtWVcA",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"filtered-subcategory-list-buttons\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,3],[14,0,\"subcategory\"],[15,6,[29,[\"/c/\",[30,2,[\"id\"]]]]],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@subcategories\",\"subcategory\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/subcategory-list-buttons.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "subcategory-list-buttons:SubcategoryListButtons"));
  var _default = _exports.default = SubcategoryListButtons;
});