define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-title-suffix/bug-report-topic-status-title-tag", ["exports", "@glimmer/component", "@ember/helper", "discourse-common/helpers/i18n", "discourse/plugins/discourse-epic-games/discourse/lib/bug-report-topic-status", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _i18n, _bugReportTopicStatus, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BugReportTopicStatusTitleTag extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model: model1
      } = _ref;
      let {
        siteSettings: siteSettings1
      } = _ref2;
      return (0, _bugReportTopicStatus.shouldRenderTopicStatus)({
        topic: model1,
        siteSettings: siteSettings1
      });
    }
    get status() {
      return (0, _i18n.default)(_constants.BUG_REPORT_TOPIC_STATUSES[this.statusTag].status);
    }
    get statusTag() {
      const matchingTag1 = this.args.outletArgs.model.get("tags").find(tag1 => {
        return Object.keys(_constants.BUG_REPORT_TOPIC_STATUSES).includes(tag1);
      });
      return matchingTag1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.statusTag}}
          <div class="topic-status-title-tag">
            <span class={{concat "topic-status-" this.statusTag}}>
              {{this.status}}
            </span>
          </div>
        {{/if}}
      
    */
    {
      "id": "aZ7UAPYQ",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"statusTag\"]],[[[1,\"      \"],[10,0],[14,0,\"topic-status-title-tag\"],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[32,0],[\"topic-status-\",[30,0,[\"statusTag\"]]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"status\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-title-suffix/bug-report-topic-status-title-tag.js",
      "scope": () => [_helper.concat],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BugReportTopicStatusTitleTag;
});