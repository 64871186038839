define("discourse/plugins/discourse-epic-games/discourse/lib/bug-report-topic-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldRenderTopicStatus = shouldRenderTopicStatus;
  function shouldRenderTopicStatus(_ref) {
    let {
      siteSettings,
      topic
    } = _ref;
    if (!siteSettings.epic_display_bug_report_status) {
      return false;
    }
    if (topic.can_vote) {
      return true;
    }
    return false;
  }
});