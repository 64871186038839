define("discourse/plugins/discourse-epic-games/discourse/connectors/discovery-list-container-top/subcategory-list-buttons-wrapper", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/subcategory-list-buttons", "@ember/component", "@ember/template-factory"], function (_exports, _component, _subcategoryListButtons, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubcategoryListButtonsWrapper extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.epic_display_subcategories_for_tag_intersection;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SubcategoryListButtons
          @subcategories={{@outletArgs.category.subcategory_list}}
        />
      
    */
    {
      "id": "+ZzRfRMp",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@subcategories\"],[[30,1,[\"category\",\"subcategory_list\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/discovery-list-container-top/subcategory-list-buttons-wrapper.js",
      "scope": () => [_subcategoryListButtons.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SubcategoryListButtonsWrapper;
});