define("discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-assistant-shortcut-top/assistant-shortcut-top", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/search-type-header", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _searchTypeHeader, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const splitShortcut = shortcuts1 => shortcuts1?.[0]?.split(":")?.[0];
  const AssistantUserTop = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.suggestionShortcuts}}
      <SearchTypeHeader @type={{splitShortcut @outletArgs.suggestionShortcuts}} />
    {{/if}}
  
  */
  {
    "id": "ISQ6DVgA",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"suggestionShortcuts\"]],[[[1,\"    \"],[8,[32,0],null,[[\"@type\"],[[28,[32,1],[[30,1,[\"suggestionShortcuts\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-assistant-shortcut-top/assistant-shortcut-top.js",
    "scope": () => [_searchTypeHeader.default, splitShortcut],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "assistant-shortcut-top:AssistantUserTop"));
  var _default = _exports.default = AssistantUserTop;
});