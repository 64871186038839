define("discourse/plugins/discourse-epic-games/discourse/initializers/bug-report-in-topic-fixed-status", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-epic-games/discourse/lib/bug-report-topic-status", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _bugReportTopicStatus, _constants, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-bug-report-in-post-fixed-status",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_display_bug_report_status) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.createWidget("bug-report-in-post-fixed-status", {
          html(_ref2) {
            let {
              topic
            } = _ref2;
            return new _renderGlimmer.default(this, "div.bug-report-in-post-fixed-status", (0, _templateFactory.createTemplateFactory)(
            /*
              <BugReportInPostFixedStatus @topic={{@data.topic}} />
            */
            {
              "id": "ByRacaDU",
              "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[\"bug-report-in-post-fixed-status\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/bug-report-in-topic-fixed-status.js",
              "isStrictMode": false
            }), {
              topic
            });
          }
        });
        api.decorateWidget("post-contents:after-cooked", dec => {
          if (dec.attrs.post_number === 1 && (0, _bugReportTopicStatus.shouldRenderTopicStatus)({
            topic: dec.attrs,
            siteSettings
          }) && dec.attrs.topic.get("tags").includes(_constants.FIXED_STATUS)) {
            return dec.widget.attach("bug-report-in-post-fixed-status", {
              topic: dec.attrs.topic
            });
          }
        });
      });
    }
  };
});