define("discourse/plugins/discourse-epic-games/discourse/lib/digital-consent", ["exports", "@ember/template", "discourse-i18n", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _template, _discourseI18n, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = triggerConsentToastHighlight;
  _exports.hasDigitalConsentPendingGroup = hasDigitalConsentPendingGroup;
  function triggerConsentToastHighlight(currentUser, toasts, consentUrl) {
    // highlight or render the consent toast if the user is in the digital_consent_pending group
    if (hasDigitalConsentPendingGroup(currentUser)) {
      const consentToast = document.querySelector(".digital-consent-toast .fk-d-default-toast.-warning");
      if (consentToast) {
        consentToast.classList.add("pulse");
        return setTimeout(() => {
          consentToast.classList.remove("pulse");
        }, 1000);
      } else {
        return toasts.warning({
          autoClose: false,
          class: "digital-consent-toast",
          data: {
            title: _discourseI18n.default.t("discourse_epic_games.digital_consent_toast.title"),
            message: (0, _template.htmlSafe)(_discourseI18n.default.t("discourse_epic_games.digital_consent_toast.message", {
              digital_consent_url: consentUrl
            })),
            icon: "ue-exclamation-triangle"
          }
        });
      }
    }

    // otherwise, return false and continue
    return false;
  }
  function hasDigitalConsentPendingGroup(currentUser) {
    if (!currentUser || !currentUser.groups) {
      return false;
    }
    return currentUser.groups.some(group => group.name === _constants.DIGITAL_CONSENT_PENDING_GROUP);
  }
});